<template>
	<div class="details">
		<div class="back">
			<svg-icon icon-class="logo" style="width: 80px; height: 40px"></svg-icon>
			<div style="font-size: 16px" @click="$router.back()">返回</div>
		</div>
		<div v-if="$route.query.flag == 1">
			<div class="title">
				<h1>变更数据捕获的力量</h1>
				<p>使用现代 ETL 摆脱批处理操作的束缚，该 ETL 在数据更改时复制数据。</p>
			</div>
			<div class="dialog1-bg1">
				<div class="p1">为什么CDC优于传统ETL？</div>
				<div class="p2-4box">
					<div class="smallp2">
						<div class="icon45deg"></div>
						<div class="p2">随着新数据库事务的发生，变更数据不断移动，使您能够立即响应时间敏感问题。</div>
					</div>
				</div>
				<div class="p2-4box">
					<div class="smallp2">
						<div class="icon45deg"></div>
						<div class="p2">CDC 最大限度地减少了源系统的开销，延长了硬件寿命，并规避了批处理窗口限制。</div>
					</div>
				</div>
				<div class="p2-4box">
					<div class="smallp2">
						<div class="icon45deg"></div>
						<div class="p2">只移动全天不断变化的数据可以节省带宽并节省通信成本。</div>
					</div>
				</div>
				<div class="p2-4box">
					<div class="smallp2">
						<div class="icon45deg"></div>
						<div class="p2">对目标数据库或数据仓库应用更改时，这些更改的顺序和事务性保持不变。</div>
					</div>
				</div>
			</div>
			<div class="dialog1-bg2">
				<div class="p1">在StaRiver RDP上更改数据功能</div>
				<div class="p2">使用现代 ETL 摆脱批处理操作的束缚，该 ETL 在数据更改时复制数据。</div>
				<div class="p3">
					在从主要企业数据库（包括 Oracle、SQLServer HPE NonStop、MySQL、PostgreSQL、MongoDB
					等）摄取时使用基于日志的更改数据捕获。它最大限度地减少了资源上的 CPU 开销，不需要更改应用程序，以及维护解决方案的大量管理开销。
				</div>
				<div class="dialog1-bg2-6box">
					<div class="smallbox">
						<div class="smallbox-p1">异构集成</div>
						<div class="smallbox-p2">
							StaRiver RDP的实时数据摄取不仅限于数据库和 CDC 方法。使用StaRiver RDP，您可以将来自 OLTP
							系统的实时事务数据与实时日志数据、消息系统、传感器数据、NoSQL 和 Hadoop 数据合并，以获得关于您的业务的丰富、全面和可靠的信息。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">维护事务上下文</div>
						<div class="smallbox-p2">
							从数据库日志中提取更改数据时，StaRiver
							RDP移动已提交的事务并维护事务上下文。在整个数据移动、处理和交付步骤中，都会保留此事务上下文，以便用户可以创建可靠的副本数据库。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">流式处理</div>
						<div class="smallbox-p2">
							StaRiver RDP提供开箱即用的转换器和内存流处理功能，可在运动时过滤、聚合、屏蔽、转换和丰富变化数据。使用基于 SQL 的连续查询，StaRiver
							RDP立即将更改数据转换为最终用户可使用的格式，而不会丢失事务上下文。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">关键任务可靠性</div>
						<div class="smallbox-p2">
							随着数据通过StaRiver RDP平台的内存组件移动和处理，解决方案记录和跟踪每个操作。如果出现中断，StaRiver
							RDP可以从中断的地方重播事务——不会丢失数据或重复。使用Striim集群的内置恢复和高可用性确保了关键任务的可靠性。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">内置数据验证</div>
						<div class="smallbox-p2">
							该平台在数据移动时不断比较源系统和目标系统，验证数据库是否一致并且所有更改的数据都已应用于目标。在必须避免数据丢失的用例中，例如迁移到新的云数据存储，此功能极大地降低了迁移风险。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">云和本地变更交付</div>
						<div class="smallbox-p2">
							使用相同的软件，StaRiver RDP不仅可以将更改数据实时传送到本地数据库，还可以传送到在云、云服务、消息传递系统、文件、Hadoop 和 NoSQL
							环境中运行的数据库。StaRiver RDP的集成数据流应用程序可以有多个具有并发实时数据交付的目标。
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="$route.query.flag == 2">
			<div class="dialog2-bg1">
				<div class="smallbox">
					<div class="p1">为什么选择智能数据管道？</div>
					<div class="p2">在正确的时间使用正确的数据做出更好、更快的决策。最适合您业务的解决方案是智能数据管道。</div>
				</div>
				<div class="smallbox">
					<div class="p1">即时访问</div>
					<div class="p2">通过连接本地和云环境来加速您的现代数据架构，并更轻松地访问业务用户。</div>
				</div>
				<div class="smallbox">
					<div class="p1">即时洞察</div>
					<div class="p2">
						智能数据管道可实时访问您的流数据。借助云数据仓库中始终可用的新数据，您可以产生可行的见解并更快地做出决策，以满足客户的期望。
					</div>
				</div>
			</div>
			<div class="dialog2-bg2">
				<div class="p1">StaRiver RDP智能数据管道的主要特点</div>
				<div class="dialog2-bg2-7box">
					<div class="smallbox">
						<div class="smallbox-p1">实时数据集成</div>
						<div class="smallbox-p2">
							实时集成意味着您可以在正确的时间访问正确的数据，从而为您的业务做出正确的决策。智能数据管道通过实时数据移动和内置连接器实现这一切，这些连接器可以将多个管道馈送到不同的数据目标。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">与位置无关</div>
						<div class="smallbox-p2">
							无论您的数据是驻留在本地还是在云端，智能数据管道都可以弥合旧服务器与您的组织蓬勃发展所需的最新应用程序之间的差距。智能数据管道是将现代数据架构结合在一起的粘合剂。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">基于流数据构建的应用程序</div>
						<div class="smallbox-p2">
							智能数据管道使您能够使用熟悉的 SQL
							轻松地在流数据上构建应用程序，以便您可以快速开始。一旦智能数据管道到位，您就可以利用机器学习和自动响应来保持竞争优势。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">可扩展性</div>
						<div class="smallbox-p2">
							根据数据需求无限扩展的能力可能导致数据成本迅速上升。智能数据管道不仅使您能够轻松扩展，而且还能够以最具成本效益的方式进行扩展。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">可靠性</div>
						<div class="smallbox-p2">
							智能数据管道可靠地交付您所有的关键工作流程并确保零停机时间。在 Smart Data Pipelines
							之前，如果不手动管理整个过程以确保没有重复消息，您永远无法保证完全一次或至少一次处理能够正常工作。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">模式演变</div>
						<div class="smallbox-p2">
							随着您的业务发展，您对各种应用程序的使用也会随之发展。随着连接的应用程序的发展，自动模式演变可帮助您跟上源数据库的变化。Smart Data
							Pipelines 配备了模式演化功能，因此用户可以准确指定他们希望如何处理 DDL 更改。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">管道监控</div>
						<div class="smallbox-p2">
							为您的数据客户提供内置仪表板和监控，以便他们可以轻松地实时监控其数据流的状态。当秒数对您的业务很重要时，Smart Data Pipelines
							可以保证数据的新鲜度。
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="$route.query.flag == 3">
			<div class="dialog3-bg1">
				<div class="smallbox">
					<div class="p1">为什么选择流式处理？</div>
					<div class="p2">在现代数据架构中使用流式 SQL 实时转换、过滤、聚合和丰富数据。</div>
				</div>
				<div class="smallbox">
					<div class="p1">流式处理之前</div>
					<div class="p2">如果没有流式处理，SQL 团队会浪费宝贵的时间甚至几天等待数据加载然后进行转换。</div>
				</div>
				<div class="smallbox">
					<div class="p1">流式处理之后</div>
					<div class="p2">借助 Streaming SQL，团队可以获得近乎即时的业务洞察力，并提供个性化的体验，让客户回头客。</div>
				</div>
			</div>
			<div class="dialog3-bg2">
				<div class="p1">StaRiver上的流式处理功能</div>
				<div class="dialog3-bg2-4box">
					<div class="smallbox">
						<div class="smallbox-p1">自动提醒让您领先一步</div>
						<div class="smallbox-p2">
							StaRiver RDP在 slack 和电子邮件中提供开箱即用的警报，可以针对各种指标进行配置，让您随时了解数据流的状态和性能。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">了解整个数据旅程</div>
						<div class="smallbox-p2">StaRiver RDP具有开箱即用的洞察力和监控功能，可一目了然地向您显示数据流的状态。</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">从您的数据流中获得更深入的 洞察力</div>
						<div class="smallbox-p2">通过深入研究数十个端到端指标（例如数据新鲜度、CPU 使用率和数据摄取率）来获得更高的可见性。</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">直观的自定义分析仪表板</div>
						<div class="smallbox-p2">
							StaRiver
							RDP可以轻松地为您的实时分析工作负载定制仪表板。使用表容量指标深入挖掘您的数据管道，对数据驱动的场景发出警报，甚至将机器学习整合到您的分析中，以更深入地了解您的实时数据。
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="$route.query.flag == 4">
			<div class="dialog4-bg1">
				<div class="smallbox">
					<div class="p1">使用新数据</div>
					<div class="p2">实时集成您的数据，无需修改或减慢源系统</div>
				</div>
				<div class="smallbox">
					<div class="p1">打破数据孤岛</div>
					<div class="p2">从各种系统中提取和交付，包括数据仓库、日志文件、异构数据库、传感器和消息队列</div>
				</div>
				<div class="smallbox">
					<div class="p1">构建现代数据架构</div>
					<div class="p2">使用端到端的企业级平台，通过流式分析通过参考数据预处理和丰富实时数据</div>
				</div>
			</div>
			<div class="dialog4-bg2">
				<div class="p1">实时数据集成变得容易</div>
				<div class="dialog3-bg2-4box">
					<div class="smallbox">
						<div class="smallbox-p1">快速入门</div>
						<div class="smallbox-p2">
							StaRiver
							RDP将实时变更数据捕获功能与动态数据处理和数据可视化相结合，为企业提供及时和分析就绪的数据。基于向导的用户界面提供了直观的开发体验，并通过预建的数据管道加快了部署时间。借助基于
							SQL 的引擎，StaRiver RDP易于业务分析师和开发人员使用。
						</div>
					</div>
					<div class="smallbox">
						<div class="smallbox-p1">为什么选择StaRiver RDP进行实时数据集成</div>
						<div class="smallbox-p2">
							借助StaRiver RDP，您可以通过集成来自数据库、消息传递系统、文件、数据仓库、数据湖和 IoT 的本地和云数据轻松采用下一代基础框架。
						</div>
					</div>
				</div>
			</div>
		</div>

		<CopyrightComponents />
	</div>
</template>

<script>
import CopyrightComponents from "@/components/Copyright/Copyright.vue";
export default {
	components: {
		CopyrightComponents,
	},
	props: {},
	data() {
		return {};
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>

<style scoped lang="scss">
.details {
}
.back {
	margin: 10px 15px 25px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #297aff;
}
.title {
	margin-bottom: 20px;
	font-size: 14px;
	padding: 0 15px;
	h1 {
		font-size: 18px;
		margin-bottom: 15px;
	}
}
.dialog1-bg1 {
	background-color: #fff;
	padding: 0 15px;

	.p1 {
		font-weight: 800;
		font-size: 16px;
		font-family: PingFang SC, PingFang SC;
		color: #297aff;
		margin-bottom: 18px;
	}

	.p2-4box {
		.smallp2 {
			display: flex;

			.icon45deg {
				width: 8px;
				height: 8px;
				background-color: #297aff;
				transform: rotate(45deg);
				margin-top: 6px;
				margin-left: 3px;
				margin-right: 15px;
			}

			.p2 {
				font-family: PingFang SC, PingFang SC;
				font-size: 14px;
				color: #000000;
				line-height: 16px;
				margin-bottom: 20px;
				flex: 1;
				line-height: 22px;
			}
		}
	}
}
.dialog1-bg2 {
	padding: 15px;
	background-color: #f7f8fb;

	.p1 {
		font-family: PingFang SC, PingFang SC;
		font-weight: 800;
		font-size: 16px;
		color: #297aff;
		margin-bottom: 18px;
	}

	.p2 {
		font-size: 14px;
		font-family: "Microsoft YaHei";
		color: #1b1b1b;
		margin-bottom: 13px;
		text-indent: 28px;
	}

	.p3 {
		font-size: 14px;
		font-family: "Microsoft YaHei";
		color: #1b1b1b;
		line-height: 24px;
		text-indent: 28px;
		text-align: justify;
	}

	.dialog1-bg2-6box {
		margin-top: 18px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.smallbox {
			width: 48%;
			background: #ffffff;
			border-radius: 5px 5px 5px 5px;
			padding: 20px 10px;
			margin-bottom: 15px;

			.smallbox-p1 {
				font-family: PingFang SC, PingFang SC;
				font-weight: 800;
				font-size: 14px;
				color: #000000;
				line-height: 16px;
				margin-bottom: 10px;
			}

			.smallbox-p2 {
				font-family: PingFang SC, PingFang SC;
				font-weight: 500;
				font-size: 14px;
				color: #595e67;
				line-height: 18px;
				text-align: justify;
			}
		}
	}
}

.dialog2-bg1 {
	padding: 15px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	background-color: #fff;

	.smallbox {
		.p1 {
			font-weight: 800;
			font-size: 16px;
			font-family: PingFang SC, PingFang SC;
			color: #297aff;
			margin-bottom: 10px;
		}

		.p2 {
			font-family: PingFang SC, PingFang SC;
			font-size: 14px;
			color: #000000;
			line-height: 16px;
			margin-bottom: 20px;
			flex: 1;
			line-height: 22px;
		}
	}
}

.dialog2-bg2 {
	padding: 15px;
	background-color: #f7f8fb;

	.p1 {
		font-family: PingFang SC, PingFang SC;
		font-weight: 800;
		font-size: 16px;
		color: #297aff;
		margin-bottom: 22px;
	}

	.dialog2-bg2-7box {
		margin-top: 25px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.smallbox {
			width: 48%;
			background: #ffffff;
			border-radius: 5px 5px 5px 5px;
			padding: 20px 10px;
			margin-bottom: 15px;

			.smallbox-p1 {
				font-family: PingFang SC, PingFang SC;
				font-weight: 800;
				font-size: 14px;
				color: #000000;
				line-height: 16px;
				margin-bottom: 10px;
			}

			.smallbox-p2 {
				font-family: PingFang SC, PingFang SC;
				font-weight: 500;
				font-size: 14px;
				color: #595e67;
				line-height: 22px;
				text-align: justify;
			}
		}
	}
}

.dialog3-bg1 {
	padding: 15px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	background-color: #fff;

	.smallbox {
		.p1 {
			font-weight: 800;
			font-size: 16px;
			font-family: PingFang SC, PingFang SC;
			color: #297aff;
			margin-bottom: 10px;
		}

		.p2 {
			font-family: PingFang SC, PingFang SC;
			font-size: 14px;
			color: #000000;
			line-height: 16px;
			margin-bottom: 20px;
			flex: 1;
			line-height: 22px;
		}
	}
}

.dialog3-bg2 {
	padding: 15px;
	background-color: #f7f8fb;

	.p1 {
		font-family: PingFang SC, PingFang SC;
		font-weight: 800;
		font-size: 16px;
		color: #297aff;
		margin-bottom: 22px;
	}

	.dialog3-bg2-4box {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.smallbox {
			width: 48%;
			background: #ffffff;
			border-radius: 5px 5px 5px 5px;
			padding: 20px 10px;
			margin-bottom: 15px;

			.smallbox-p1 {
				font-family: PingFang SC, PingFang SC;
				font-weight: 800;
				font-size: 14px;
				color: #000000;
				line-height: 16px;
				margin-bottom: 10px;
			}

			.smallbox-p2 {
				font-family: PingFang SC, PingFang SC;
				font-weight: 500;
				font-size: 14px;
				color: #595e67;
				line-height: 22px;
				text-align: justify;
			}
		}
	}
}

.dialog4-bg1 {
	padding: 15px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	background-color: #fff;

	.smallbox {
		.p1 {
			font-weight: 800;
			font-size: 16px;
			font-family: PingFang SC, PingFang SC;
			color: #297aff;
			margin-bottom: 10px;
		}

		.p2 {
			font-family: PingFang SC, PingFang SC;
			font-size: 14px;
			color: #000000;
			line-height: 16px;
			margin-bottom: 20px;
			flex: 1;
			line-height: 22px;
		}
	}
}

.dialog4-bg2 {
	padding: 15px;
	background-color: #f7f8fb;

	.p1 {
		font-family: PingFang SC, PingFang SC;
		font-weight: 800;
		font-size: 16px;
		color: #297aff;
		margin-bottom: 22px;
	}

	.dialog3-bg2-4box {
		margin-top: 25px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.smallbox {
			width: 48%;
			background: #ffffff;
			border-radius: 5px 5px 5px 5px;
			padding: 20px 10px;
			margin-bottom: 15px;

			.smallbox-p1 {
				font-family: PingFang SC, PingFang SC;
				font-weight: 800;
				font-size: 14px;
				color: #000000;
				line-height: 16px;
				margin-bottom: 10px;
			}

			.smallbox-p2 {
				font-family: PingFang SC, PingFang SC;
				font-weight: 500;
				font-size: 14px;
				color: #595e67;
				line-height: 22px;
				text-align: justify;
			}
		}
	}
}
</style>
